@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@500;400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap');
html{
    scroll-behavior: smooth;
}
body{
    background:#00254c;
    font-family:"Rubik", sans-serif;
}
#banner{
    background-image:url("./Images/bgs/banner.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height:100%;
    width:100%;
}

.my-banner-content{
    background-color: rgba(0,0,0,0.5);
    height:100%;
    width:100vw;
}
.banner-content{
    padding-top:50px;
    padding-bottom:50px;
}
.social-link{
    height:30px;
    width:30px;
}
.social{
    margin-right:5px;
    margin-left:5px;
}
.logo{
    height:70px;
    width:100%;
    border-radius:50%;
}
.nav-link{
    color:white;
    font-family:"Alfa Slab One", sans-serif;
    font-size:24px;
    margin-right:30px;
}
.nav-link:hover{
    color:#6feb9d;
    transition:0.7s;
}
.banner-h1-1{
    font-size:24px;
    font-family:"Alfa Slab One", sans-serif;
    color:#6feb9d;
}
.banner-h1-2{
    font-size:34px;
    font-family:"Alfa Slab One", sans-serif;
    color:#6feb9d;
    position:relative;
    bottom:5px;
}
.banner-h1-3{
    font-size:64px;
    font-family:"Alfa Slab One", sans-serif;
    color:#6feb9d;
    position:relative;
    bottom:10px;
}
.banner2{
    font-size:64px;
    color:#6feb9d;
    font-family:"Alfa Slab One", sans-serif;
}
.beach-banner{
    position:relative;
    bottom:15px;
}
.banner-content{
    margin-top:0px;
}
.mint-btn{
    background-color:#6feb9d;
    padding:20px 80px;
    color:#1d1338;
    font-family:"Alfa Slab One", sans-serif;
    font-size:25px;
}
.mint-btn:hover{
    background-color: #35e375;
    transition:0.7s;
}
.banner-p{
    font-size:24px;
    color:white;
    position:relative;
    bottom:25px;
    font-family:"Alfa Slab One", sans-serif;
}
.mygif{
    height:400px;
    width:400px;
    border-radius:10px;
    margin-bottom:40px;
    margin-top:50px;
    box-shadow: rgba(53,227,117, 0.19) 0px 10px 20px, rgba(53,227,117, 0.23) 0px 6px 6px;
}
.myhr{
    border:3px solid #35e375;
    margin-top:40px;
}

/* vision  */
.vision-content{
  padding-top:30px;
}
.nftimg{
  height:300px;
  width:300px;
}
.mymarquee{
  margin-top:50px;
}
/* about  */

#about{
    background-image:url("./Images/bgs/who.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height:100%;
    width:100%;
}
.about-cnt{
    background-color: rgba(0,0,0,0.7);
    height:100%;width:100%;
}
.about-h1{
    font-size:40px;
    font-family:"Alfa Slab One", sans-serif;
    color:#6feb9d;
    text-align:center;
    text-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
.about-content{
    padding-top:8%;
    padding-bottom:40px;
}
.about-p{
    color: white;
    font-size:20px;
    text-align:center;
    margin-top:30px;
}
.img1{
    height:100%;
    width:109.9%;
}
.myrow{
    margin-top:50px;
}
.message{
  color:white;
}
/* roadmap  */
/* roamdmap */
#roadmap1{
    background-image:url("./Images/bgs/roadmap.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height:100%;
    width:100%;
    position:relative;
    bottom:10px;
}

.roadmap-cnt{
    height:100%;
    width:100%;
    background-color: rgba(0,0,0,0.7);
}
/* timeline */
.timeline ul li {
    list-style-type: none;
    position: relative;
    width: 6px;
    margin: 0 auto;
    padding-top: 50px;
    background: #1d1338;
    box-shadow: black 0px 2px 18px 0px;
  }
   
  .timeline ul li::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: inherit;
    z-index: 1;
  }
  .timeline ul li div {
    position: relative;
    bottom: 0;
    width: 400px;
    padding: 15px;
    background: #1d1338;
    border-radius: 10px;
    box-shadow: black 0px 2px 18px 0px;
  }
   
  .timeline ul li div::before {
    content: '';
    position: absolute;
    bottom: 7px;
    width: 0;
    height: 0;
    border-style: solid;
  }
  .timeline ul li:nth-child(odd) div {
    left: 45px;
  }
   
  .timeline ul li:nth-child(odd) div::before {
    left: -15px;
    border-width: 8px 16px 8px 0;
    border-color: transparent #1d1338;
  }
  .timeline ul li:nth-child(even) div {
    left: -439px;
  }
  .in-view{
      color:black;
      font-weight:400;
  }
   
  .timeline ul li:nth-child(even) div::before {
    right: -15px;
    border-width: 8px 0 8px 16px;
    border-color: transparent transparent transparent #1d1338;
  }
  .timeline ul li::after {
    background: #6feb9d;
    transition: background .5s ease-in-out;
  }
   
  .timeline ul li.in-view::after {
    background: #6feb9d;
  }
   
  .timeline ul li div {
    visibility: hidden;
    opacity: 0;
    transition: all .5s ease-in-out;
  }
   
  .timeline ul li:nth-child(odd) div {
    transform: translate3d(200px,0,0);
  }
   
  .timeline ul li:nth-child(even) div {
    transform: translate3d(-200px,0,0);
  }
   
  .timeline ul li.in-view div {
    transform: none;
    visibility: visible;
    opacity: 1;
  }
  .roadmap-h1{
    font-size:50px;
    font-family:"Alfa Slab One", sans-serif;
    color:#6feb9d;
    text-align:center;
    text-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    padding-top:50px;
}
.roadmap-p{
    color:#8267cb;
    text-align:center;
    margin-top:20px;
    font-size:20px;
}
time{
    font-size:30px;
    font-family:"Alfa Slab One", sans-serif;
    color:#6feb9d;
    text-align:center;
    padding-left:10px;
    text-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
.in-view{
    color:white;
    border-radius:10px;
}
.in-view p{
    margin-top:10px;
    color:#8c8c8c;
    padding-left:10px;
    padding-right:10px;
}

#vision{
  background-image:url("./Images/bgs/faq.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height:100%;
    width:100%;
}
/* faq  */
#faq{
    background-image:url("./Images/bgs/faq.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height:100%;
    width:100%;
}
.faq-cnt{
    background: rgba(0,0,0,0.7);
}
.faq-content{
    padding-top:100px;
    padding-bottom:100px;
}
.faq-h1{
    font-size:50px;
    font-family:"Alfa Slab One", sans-serif;
    color:#6feb9d;
    text-align:center;
    padding-left:10px;
    text-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
.faq-p{
    text-align:center;
    margin-top:10px;
    color:#8c8c8c;
}
.question{
    margin-top:20px;
    background-color:#1d1338;
    color:#6feb9d;
    padding:20px 40px;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;
    font-size:30px;
    font-family:"Alfa Slab One", sans-serif;
}
.question:hover{
    cursor:pointer;
}
.answer{
    color:#8c8c8c;
    font-size:16px;
    font-weight:500;
    margin-top:30px;
    line-height: 1.8;
    font-family:"Rubik", sans-serif;
}
.fa-angle-down{
    position:absolute;
    right:13%;
    font-size:40px;
    color:#8067e9
}

/* footer  */
/* footer  */
#footer{
    margin-top:0px;
    text-align: center;
    background-color: #00254c;
}
.footer-p{
    color:#6feb9d;
    font-size:21px;
    font-weight:500;
    padding-top:20px;
    font-family:"Alfa Slab One", sans-serif;
    padding-bottom:0px;
}
.footer-icon{
    height:25px;
    width:25px;
    margin-top:20px;
   margin-left:5px;
}

/* partner */
.right-h1{
    color:white;
    font-size:60px;
    margin-top:50px;
}
.partner-h1{
    font-size:60px;
    font-family:"Alfa Slab One", sans-serif;
    color:#6feb9d;
    text-align:center;
    padding-left:10px;
    text-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
.third{
    font-size:100px;
    color:white;
}
.partner-left{
    margin-top:180px;
}

/* team  */
.team-content{
    padding-top:50px;
    padding-bottom:50px;
}
#team{
    background-image:url("./Images/bgs/team.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height:100%;
    width:100%;
}
.team-cnt{
    background-color: rgba(0,0,0,0.7);
    height:100%;
    width:100%;
}
.team-row{
    padding-bottom:40px;
}
.team-h1{
    margin-bottom:50px;
}
.member-name{
    font-size:30px;
    font-family:"Alfa Slab One", sans-serif;
    color:#6feb9d;
    text-align:center;
    padding-left:10px;
    margin-top:30px;
    text-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
.member-image{
    height:380px;
    width:380px;
    border-radius:10px;
}
.designation{
    font-size:20px;
    font-family:"Alfa Slab One", sans-serif;
    color:#6feb9d;
    text-align:center;
    padding-left:10px;
    text-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
@media (max-width:500px)
{
    
    #banner{
        background-image:url("./Images/bgs/banner.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        height:100%;
        width:100%;
    }
    .banner-content{
        padding-top:20px;
        padding-bottom:100px;
    }
    .member-image{
        height:300px;
        width:300px;
        border-radius:10px;
    }
    .content-h1{
        font-size:28px;
    }
    .second-row{
        margin-left:5px; margin-right:5px;
    }
    .team-card-1{
        margin-top:20px;
    }
    .timeline{
        position: relative;
        right:45px;
    }
    .timeline ul li {
        margin-left: 20px;
      }
       
      .timeline ul li div {
        width: calc(100vw - 91px);
      }
       
      .timeline ul li:nth-child(even) div {
        left: 45px;
      }
       
      .timeline ul li:nth-child(even) div::before {
        left: -15px;
        border-width: 8px 16px 8px 0;
        border-color: transparent #1d1338 transparent transparent;
      }
      .faq-h1{
        font-size:28px;
    }
    .question{
        font-size:18px;
    }
    .footer-icon{
        margin-top:0px;
        margin-bottom: 10px;
    }
    .roadmap-h1{
        font-size:30px;
    }
    .roadmap-p{
        font-size:18px;
    }

    .banner-h1-1{
        font-size:25px;
        font-family:"Alfa Slab One", sans-serif;
        color:#6feb9d;
    }
    .banner-p{
        font-size:20px;
    }
    .banner-h1-2{
        font-size:30px;
        font-family:"Alfa Slab One", sans-serif;
        color:#6feb9d;
        position:relative;
        bottom:5px;
    }
    .banner-h1-3{
        font-size:70px;
        font-family:"Alfa Slab One", sans-serif;
        color:#6feb9d;
        position:relative;
        bottom:20px;
    }
    .banner-content{
        margin-top:40px;
    }
    .mint-btn{
        background-color:#6feb9d;
        padding:15px 30px;
        color:#1d1338;
        font-family:"Alfa Slab One", sans-serif;
        font-size:25px;
    }
    .mygif{
        height:300px;
        width:300px;
        margin-top:0px;
    }
    .myhr{
        margin-top:20px;
    }

    /* about  */
    .about-h1{
        font-size:28px;
    }
    .about-p{
        font-size:18px;
    }

    .img1{
        height:350px;
        width:100%;
        border-radius:10px;
        margin-top:20px;
    }
    .myrow, .myrow2{
        margin-left:5px;
        margin-right:5px
    }
    .question{
        padding-left:15px;
        font-size:24px;
    }
    .fa-angle-down{
        display:none;
    }
    .logo{
        height:60px;
        width:60px;
    }

    /* partner */
.right-h1{
    color:white;
    font-size:40px;
    margin-top:50px;
}
.partner-h1{
    font-size:40px;
    font-family:"Alfa Slab One", sans-serif;
    color:#6feb9d;
    text-align:center;
    padding-left:10px;
    text-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
.third{
    font-size:70px;
    color:white;
}
.partner-left{
    margin-top:50px;
}
.second-span{
    font-size:40px;
}

.banner-h1-2{
    font-size:35px;
    font-family:"Alfa Slab One", sans-serif;
    color:#6feb9d;
    position:relative;
    bottom:5px;
}
.banner-h1-3{
    font-size:40px;
    font-family:"Alfa Slab One", sans-serif;
    color:#6feb9d;
    position:relative;
    bottom:20px;
}
.banner2{
    font-size:40px;
}
}